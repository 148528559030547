<template>
    <div v-if="user">
        <div class="pb-2 mb-3 border-bottom">
            <h2>My Profile</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">My Profile</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_success">
                <b-alert
                :show="alertDismissCountDown"
                dismissible
                variant="success"
                @dismissed="alertDismissCountDown=0"
                @dismiss-count-down="alertCountDownChanged"
                >{{api_success}}</b-alert>
            </div>

            <div v-if="api_error">
                <b-alert
                :show="alertDismissCountDown"
                dismissible
                variant="danger"
                @dismissed="alertDismissCountDown=0"
                @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">
                                <h5>My Profile</h5>
                            </div>
                        </div>
                        <div class="card-body">

                            <div class="row mb-2">
                                <div class="col-md-2">
                                    <b>Name:</b>
                                </div>
                                <div class="col-md-8">
                                    {{ user.first_name }}
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-md-2">
                                    <b>Email:</b>
                                </div>
                                <div class="col-md-8">
                                    {{ user.email }}
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-md-2">
                                    <b>Password:</b>
                                </div>
                                <div class="col-md-8">
                                    <a
                                        v-b-modal.modal-prevent-closing
                                        style="color:#007bff; cursor:pointer;"
                                    >Change</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal
                id="modal-prevent-closing"
                ref="modal"
                title="Change Your Password"
                @show="resetModal"
                centered
                hide-footer
            >
            <ValidationObserver ref="form">
                <form @submit.prevent="onSubmit">
                    <div class="form-group row">
                        <label class="col-md-5">
                            Current Password
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-7">
                            <ValidationProvider
                                name="password"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <input
                                    type="password"
                                    class="form-control"
                                    aria-describedby="passwordHelpInline"
                                    v-model="oldPassword"
                                    autocomplete="off"
                                />
                                <small
                                    v-if="errors.length != 0"
                                    id="passwordHelpInline"
                                    class="text-danger field"
                                >{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-5">
                            New Password
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-7">
                            <ValidationProvider
                                name="new password"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <input
                                    type="password"
                                    class="form-control"
                                    aria-describedby="newpasswordHelpInline"
                                    v-model="password"
                                    autocomplete="off"
                                />
                                <small
                                    v-if="errors.length != 0"
                                    id="newpasswordHelpInline"
                                    class="text-danger field"
                                >{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-5">
                            Confirm Password
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-7">
                            <ValidationProvider
                                name="confirm password"
                                rules="required|confirmed:new password"
                                v-slot="{ errors }"
                            >
                                <input
                                    type="password"
                                    class="form-control"
                                    aria-describedby="confirmpasswordHelpInline"
                                    v-model="passwordConfirmation"
                                    autocomplete="off"
                                />
                                <small
                                    v-if="errors.length != 0"
                                    id="confirmpasswordHelpInline"
                                    class="text-danger field"
                                >{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div slot="modal-footer" class="w-100">
                        <b-button
                            variant="primary"
                            size="md"
                            class="float-right"
                            type="submit"
                        >Change Password</b-button>
                        <b-button
                            variant="secondary"
                            size="md"
                            class="float-right"
                            style="margin-right:10px;"
                            @click="$bvModal.hide('modal-prevent-closing')"
                        >Cancel</b-button>
                    </div>
                </form>
            </ValidationObserver>
            </b-modal>
        </div>
    </div>
</template>

<script>
import default_error from "../../assets/js/global";
export default {
    data() {
        return {
            user: {},
            password: "",
            passwordConfirmation: "",
            oldPassword: "",
            changePasswordStatus: false,
            api_error: "",
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            api_success: "",
        };
    },
    mounted() {
        const data = window.$cookies.get("user");
        this.user = data.user_details;
    },
    methods: {
        onSubmit () {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                this.changePassword();
            });
        },
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        resetModal() {
            this.password = "";
            this.passwordConfirmation = "",
            this.oldPassword = "";
        },
        changePassword() {
            this.startProgressBar();
            this.axios
                .post(
                    this.$api.change_password,
                    { password: this.password, password_confirmation:this.passwordConfirmation,old_password: this.oldPassword },
                    this.getAuthHeaders()
                )
                .then(response => {
                    this.changePasswordStatus = true;
                    this.$nextTick(() => {
                        this.$refs.modal.hide();
                    });
                    this.api_error = "";
                    this.api_success = response.data.message;
                    this.showAlert();
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.changePasswordStatus = false;
                    this.api_success = "";
                    this.showAlert();
                    this.updateProgressBar(false);
                    this.$bvModal.hide('modal-prevent-closing');
            });
        },
    }
};
</script>
<style lang="scss">
.card-subtitle {
    text-transform: capitalize;
}
.employee_address{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell,
    "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
}
</style>
